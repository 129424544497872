

/* === new  */

.offer-staffing-app-container {
    display: flex;
    gap: 20px; 
    /* padding: 20px; */
    background : linear-gradient(180deg, #D7E1FF 0%, #EEF1FA 80.5%, #FFF 100%)!important;
}

.offer-staffing-progress-container {
    /* display: flex; */
    color: #333;
    padding: 20px;
    border-radius: 8px;
}

.offer-staffing-progress-title {
    font-size: 24px;
    font-weight: bold;
    color: #3e4784;
    margin-bottom: 5px;
}


.offer-staffing-street-view-container {
    flex: 2;
    padding: 20px;
    margin-top: 3%;
    align-items: center;
    /* padding-left: 20px; */
    border-radius: 8px;
    width: 38vw;
}

.offer-staffing-order-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.offer-staffing-order-name, .offer-staffing-order-id {
    font-weight: bold;
    color: #333;
}

.offer-staffing-order-address {
    font-size: 14px;
    color: #666;
}

.offer-staffing-order-total {
    font-weight: bold;
    color: #333;
    margin-top: 5px;
}

.offer-googlestreetview {
    flex-grow: 1; 
    height: 400px; 
    /* margin-top: 5px; */
    overflow: hidden; 
    border-radius: 8px; 
}

.offer-googlestreetview > div {
    border-radius: 8px;
    height: 100%; /* Full height of the card */
    width: 100%;
}



.offer-staffing-status-item {
    color: #666;
    font-size: 14px;
    margin-bottom: 20px;
}

/* offer status profile selectors on click  */
.offer-status-profiles-outer-container {
  overflow: hidden;
  border-radius: 16px;
  background: #F9FAFB;
  padding: 12px;
  max-width: 95%;
  box-sizing: border-box;
}

.offer-status-profiles-container {
  display: flex;
  gap: 12px; /* Reduce space between profile cards */
  padding: 6px 0;
  overflow-x: auto;
  scrollbar-width: thin;
  height: 90px; /* Adjust height to fit smaller profiles */
  align-items: center;
}

.offer-status-profile-card {
  width: 80px; /* Make it smaller */
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
}

.offer-status-profile-circle {
  width: 44px; /* Smaller circle */
  height: 44px;
  border-radius: 50%;
  border: 2px solid #E4E4E4;
  background: lightgray;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2px; /* Reduce space below the image */
}

.offer-status-profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.offer-status-active {
  border: 2px solid #DB2877 !important;
  /* border: 2px solid #3D4784 !important; */
  padding: 5px;

    /* background: rgba(61, 71, 132, 0.15); 
    border-radius: 12px; 
    padding: 4px; */

    transform: scale(1.1); /* Slight zoom */
    transition: transform 0.2s ease-in-out;
}

.offer-status-profile-name {
  color: #000;
  font-family: 'Inter', sans-serif;
  font-size: 12px; /* Reduce font size */
  font-weight: 500;
  line-height: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
}

.offer-status-text {
  font-size: 10px; /* Make status text smaller */
  font-weight: 500;
  text-align: center;
  color: #6B7280;
  margin-top: 1px;
}

/* Add colors based on status */
.offer-status-text.pending {
  color: #F59E0B; /* Orange */
}

.offer-status-text.approved {
  color: #10B981; /* Green */
}

.offer-status-text.rejected {
  color: #EF4444; /* Red */
}

.offer-status-text.disputed {
  color: #DC2626; /* Bright Red */
  font-weight: 700; /* Make it stand out */
}


  .offer-status-profiles-container::-webkit-scrollbar {
    height: 8px;
  }
  
  .offer-status-profiles-container::-webkit-scrollbar-thumb {
    border-radius: 99px;
    background: #F2F2F2;
  }
  
  .offer-status-profiles-container::-webkit-scrollbar-track {
    background: transparent;
  }