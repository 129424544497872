.recommended-staff-listing-view_box {
    border: 1px solid #ddd;
    padding: 16px;
    transition: transform 0.2s ease-in-out;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.05);
}


.recommended-staff-listing-view_box:hover {
    transform: scale(1.01);
}


.recommended-staff-listing {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Space between sections */
}


.recommended-staff-listing-listingDetails_section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    font-weight: 500;
    font-size: 12.5px;
    color: #4F4F4F;
}

.closest-staff-listing-listingDetails_section {
    display: grid;
    grid-template-columns: repeat(0, 5fr);
    gap: 16px;
    font-weight: 500;
    font-size: 12.5px;
    color: #4F4F4F;
    
}

.recommended-staff-listing-title,
.recommended-staff-listing-address,
.recommended-staff-listing-daily_update {
    margin-bottom: 10px;
}


#listing-billboard .recommended-staff-listing-listingDetails_section .view_details_box .view_box .request_info .info {
    font-weight: 600;
    font-size: 12.5px;
    color: #db2777;
    /* color: #F16022; */
}


#listing-billboard .recommended-staff-listing-listingDetails_section .title {
    /* margin-bottom: 6px;
    font-size: 20px;
    line-height: 24px;
    color: #333333; */
    align-items: center;
    color: #000 !important;
    font-size: 16px !important;
    font-weight: 600 !important; 
    margin-bottom: 4px !important; 
}
#listing-billboard .recommended-staff-listing-listingDetails_section .title .listing-daily_impre span{
    padding: 8px;
    border-radius: 8px;
    background: #F5F5F5;
    color: #000;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
}

#listing-billboard .recommended-staff-listing::-webkit-scrollbar {
    /* display: none; */
    width: 5px; /* Mostly for vertical scrollbars */    
}

#listing-billboard .recommended-staff-listing::-webkit-scrollbar-thumb { /* Foreground */
    background: #999;
    border-radius: 8px;
  }
.recommended-staff-listing-view_box .address{
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
}
.recommended-staff-listing-view_box .address svg{
    color: black;
    width: 16px;
    height: 16px;
}

.recommended-staff-listing-view_box .daily_update{
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
    margin-bottom: 8px;
}
.recommended-staff-listing-view_box .daily_update .daily_impre{
    border-radius: 99px;
    background: #F7F8FA;
    padding: 8px;
    display: flex;
    align-items: center;
    gap: 4px;
    color: #000;
    font-size: 12px;
    font-weight: 500;
}
.recommended-staff-listing-view_box .assigned-shifts{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.recommended-staff-listing-view_box .assigned-shifts .label{
    color: #616161;
    font-size: 12px;
    font-weight: 400;
}
.recommended-staff-listing-view_box .assigned-shifts .rs-tag-group .rs-tag{
    color: #3683BF;
    font-size: 12px;
    font-weight: 600;
    border-radius: 4px;
    background: #DDF3FD;
    padding: 4px;
}
.recommended-staff-listing-view_box .hr{
    margin: 16px 0;
    border-color: #E4E4E4;
    opacity: 1;
}
.recommended-staff-listing-view_box .hourl_rate .info{
    display: flex;
    align-items: center;
    gap: 8px;
}
.recommended-staff-listing-view_box .hourl_rate .info .lable{
    color: #616161;
    font-size: 12px;
    font-weight: 400;
}
.recommended-staff-listing-view_box .hourl_rate .info .selectBox{
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid #E4E4E4;
    background: #FFF;
}
.recommended-staff-listing-view_box .hourl_rate .info .selectBox,
.recommended-staff-listing-view_box .hourl_rate .info .selectBox .rs-input-number input{
    color: #000 !important;
    font-size: 12px !important;
    font-weight: 500 !important;
}
.recommended-staff-listing-view_box .hourl_rate .info .selectBox .rs-input-number{
    border: none;
    background: transparent;
    color: #000;
    font-size: 12px;
    font-weight: 500;
    outline: none;
    box-shadow: none;
    padding: 0;
}
.recommended-staff-listing-view_box .hourl_rate .info .selectBox .rs-input-number input{
    padding: 0;
}
.recommended-staff-listing-view_box .hourl_rate .make_offer_btn{
    padding: 12px;
    border-radius: 8px;
    background: #404880;
    color: #FFF;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 8px;
}

#listing-billboard .sort_by .rs-btn {
    height: 25px;
    background-color: #ffff;
    border: 1px solid #db2777;
}

.rs-dropdown-toggle-caret {
    color: #db2777;
    font-weight: 600 !important;
    top: 0 !important
}

.recommended-staff-listing-category {
    color: #000;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 8px;
}
.alert_box{
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    border-radius: 8px;
    background: #E8FEE6;
    margin-bottom: 16px;
}

@media (max-width: 1400px)  {
    .recommended-staff-listing-listingDetails_section{
        grid-template-columns: 1fr;
    }
}