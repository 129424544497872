

.offer-staffing-progress-details {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-weight: 500;
    color: #4F4F4F;
    font-size: small;
}

.offer-staffing-progress-details p {
    margin: 0 20px 0 0;
    white-space: nowrap;
}


#offer-stepper .rs-steps-item-title {
    font-size: 16px;
    color: #3e4784 !important ; 
    font-weight: 500;
}

#offer-stepper .rs-steps-item-description {
    font-size: 14px;
    color: #4F4F4F;
    font-weight: 500;
}



#offer-stepper-price-bidding .rs-input-group {
    width: 80px !important ; 
}


#offer-stepper-price-bidding button, input, optgroup, select, textarea{
    /* color: #3e4784!important ;  */

    color: #4F4F4F !important;
    font-weight: 500 !important;
}


#offer-stepper .rs-steps-item-status-process .rs-steps-item-icon-wrapper {
    background-color: #3d4784 !important ; 
    border-color: #3d4784 !important ; 
    /* color: #DB2677 !important ; */
    /* background-color: #DB2677 !important ; */
}

#offer-stepper .rs-steps-item-status-process .rs-steps-item-tail, .rs-steps-item-status-process .rs-steps-item-title:after, .rs-steps-item-tail, .rs-steps-item-title:after {
    border-color: #3d4784 ;
}



.offer-map-container {
    display: flex;
    gap: 20px; 
    align-items: flex-start; 
    justify-content: center; 
    width: 100%; 
    margin-top: 20px; 
    padding: 10px; 
    max-width: 1200px; 
}


.offer-map-container > .offer-stepper {
    flex: 1 1 60%; 
    min-width: 300px; 
}

.offer-map-container > .distance-map {
    flex: 1 1 60%; 
    min-width: 400px; 
}


@media (max-width: 768px) {
    .offer-map-container {
        flex-direction: column; 
        gap: 10px; 
    }

    .offer-map-container > * {
        flex: none; 
        width: 100%; 
    }
}

/* ====== new status ================  */

/* Styles for Dispute Resolution Section */
.dispute-resolution {
    background: #fff4e5; /* Soft warm background */
    padding: 12px;
    border-left: 4px solid #CC457D; /* Themed accent border */
    margin-top: 10px;
    border-radius: 5px;
  }
  .dispute-action-container {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between SelectPicker & button */
  }
  
  /* Make sure the button is compact */
  .resolve-button {
    background: #404880;
    color: #FFF;
    border: none;
    padding: 6px 10px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
  }
  
  .resolve-button:hover {
    background: #353a66;
  }
  
  /* Dispute Warning Text */
  .dispute-warning {
    color: #CC457D; /* Themed warning color */
    font-weight: bold;
    margin-bottom: 8px;
  }
  

  /* Payment Status Styling */
  .payment-status {
    font-weight: bold;
    text-transform: capitalize;
  }
  
  /* Style the "View Receipt" link */
  .receipt-link {
    color: #2563eb; /* Keep the standard blue for consistency */
    text-decoration: none;
    font-weight: bold;
  }
  
  .receipt-link:hover {
    text-decoration: underline;
  }
  